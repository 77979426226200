@use "src/styles/typography";
@use "src/styles/palette";
@use "src/styles/layout";

.video_thumb {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .overlay {
    position: absolute;
    border-radius: 0.25rem;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .thumbnail {
    display: block;
    object-fit: cover;
    border-radius: 0.25rem;
  }

  .overlay_content {
    position: absolute;
    text-align: center;

    .title {
      @extend .header_3;
      color: palette.$white;
    }
  }

  &.active {
    .overlay {
      background: transparent;
    }
  }
}
